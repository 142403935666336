import { determineRouteAccess } from "../globalStore/utils/helpers";
import { isEqual } from "lodash";
import { lazy, Suspense } from "react";
import { onLogout } from "../globalStore/slices/user/userSlice";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userSelectors } from "../globalStore/slices/user/userSlice";
import { useSelector } from "react-redux";
import Assets from "../pages/assets/assets/assets";
import AssetSnapshot from "../pages/assetSnapshot/AssetSnapshot";
import AuthLayout from "../components/Layout/AuthLayout";
import BatchHistory from "../pages/log/BatchHistory";
import EditOrganization from "../pages/organizations/EditOrganization";
import EditUser from "../pages/users/EditUser";
import Facilities from "../pages/facilities/Index";
import FacilityOverview from "../pages/facilities/FacilityOverview/FacilityOverview";
import GenerateCQR from "../pages/qr/GenerateCQR";
import Inventory from "../pages/inventory/inventory/Inventory";
import moment from "moment-timezone";
import Organizations from "../pages/organizations/Organizations";
import Products from "../pages/products/Products";
import SplashPage from "../pages/splashPage/SplashPage";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

// Routes that need to be lazy loaded. This is to control our bundle size
const Batches = lazy(() => import("../pages/batches/Batches"));
const Devices = lazy(() => import("../pages/devices/Devices"));
const PCABuilder = lazy(() => import("../pages/pcaBuilder/pcaBuilder"));
const Settings = lazy(() => import("../pages/settings/Settings"));
const Users = lazy(() => import("../pages/users/Users"));

const AuthContainer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { auth = {}, setAuth = () => { } } = props;
  const {
    appUserTypeSelector,
    checkSessionSelector,
    userAvailableActionsSelector,
    userAvailableViewsSelector
    // viewPermissionsSelector, 
  } =
    userSelectors;

  // auth and routing data
  const appUserType = useSelector(appUserTypeSelector);
  // TODO: part of cleanup, do we need to remove viewPermissions from global state
  // const viewPermissions = useSelector(viewPermissionsSelector);
  const checkSession = useSelector(checkSessionSelector, isEqual);
  const userAvailableActions = useSelector(userAvailableActionsSelector);
  const userAvailableViews = useSelector(userAvailableViewsSelector);


  // KEEP THESE GLOBAL STATE OBJECTS IN TOP LEVEL AND PASS THROUGH PROPS, AS A RE-RENDER WOULD NOT BE UNDESIRABLE IF THESE EVER CHANGED
  const appUserId = useSelector((state) => state.user.appUserId);
  const token = useSelector((state) => state.user.token);
  const organizationId = useSelector((state) => state.user.organizationId);
  const organization = useSelector((state) => state.organization.organization);
  const userActions = useSelector((state) => state.user.userActions, isEqual);
  const usersConsoleRole = useSelector((state) => state.user.usersConsoleRole);
  const usersConsoleRoles = useSelector((state) => state.user.usersConsoleRoles);
  const timeZone = useSelector(
    (state) => {
      const browserTimeZone = moment.tz.guess();

      if (state.user.userTimeZone) {
        return state.user.userTimeZone;
      } else {
        return browserTimeZone;
      }
    },
    isEqual
  );

  const logout = () => {
    dispatch(onLogout());
    setAuth((auth) => {
      return {
        ...auth,
        isAuthed: false,
      };
    });
    history.push("/login");
  };

  // Redux selectors take two arguments, the selector function argument (e.g., state=>state.organization.facilities[facilityId])) and an optional equality function (default equality function is shallow comparison, which doesn't work with arrays or objects)

  const PrivateRoute = ({
    component: Component,
    accessGranted = true,
    ...rest
  }) => {
    if (!accessGranted) {
      return (
        <Route
          {...rest}
          render={() => (
            <div>
              You are not authorized to view {rest.location.pathname || "this"}{" "}
              in LXConnect. Please contact your system administator and inquire
              about your permissions in LXConnect.
            </div>
          )}
        />
      );
    } else {
      return (
        <Route
          {...rest}
          render={(props) => (
            <>
              <Component
                {...props}
                apiUrl={apiUrl}
                appUserType={appUserType}
                key={rest?.location?.pathname}
                organization={organization}
                organizationId={organizationId}
                timeZone={timeZone}
                token={token}
                userId={appUserId}
                userAvailableActions={userAvailableActions}
                userAvailableViews={userAvailableViews}
                userRoles={userActions}
                usersConsoleRole={usersConsoleRole}
                usersConsoleRoles={usersConsoleRoles}
              />
            </>
          )}
        />
      );
    }
  };

  // as long as the session is valid, render auth container
  return checkSession.sessionValid ? (
    <AuthLayout apiUrl={apiUrl} organizationId={organizationId} token={token}>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>

          {/* Home */}
          <PrivateRoute exact path={["/", "/home"]} component={SplashPage} />

          {/* Generate CQR */}
          <PrivateRoute exact path="/generateCQR/" component={GenerateCQR} />

          {/* Asset History */}
          <PrivateRoute
            exact
            path="/assets"
            component={Assets}
            accessGranted={determineRouteAccess("/assets", userAvailableViews)}
          />

          {/* Asset Snapshot */}
          <PrivateRoute
            exact
            path="/assetSnapshot/:assetId"
            component={AssetSnapshot}
            accessGranted={determineRouteAccess("/assetSnapshot/:assetId", userAvailableViews)}
          />

          <PrivateRoute
            exact
            path="/inventorySnapshot/:assetId"
            component={AssetSnapshot}
            accessGranted={true}
          />

          {/* Batches */}
          <PrivateRoute
            exact
            path="/batches"
            component={Batches}
            accessGranted={determineRouteAccess("/batches", userAvailableViews)}
          />

          {/* Batch History */}
          <PrivateRoute
            exact
            path="/batches/:batchId/history/:assetType*"
            component={BatchHistory}
            accessGranted={determineRouteAccess("/batches/:batchId/history/:assetType*", userAvailableViews)}
          />

          {/* Inventory */}
          <PrivateRoute
            exact
            path="/inventory"
            component={Inventory}
            accessGranted={determineRouteAccess("/inventory", userAvailableViews)}
          />

          {/* Organizations */}
          <PrivateRoute
            exact
            path="/organizations"
            component={Organizations}
            accessGranted={determineRouteAccess("/organizations", userAvailableViews)}
          />

          {/* Organization */}
          <PrivateRoute
            exact
            path="/organizations/:organizationId"
            component={EditOrganization}
            accessGranted={determineRouteAccess("/organizations/:organizationId", userAvailableViews)}
          />

          {/* Facilities */}
          <PrivateRoute
            exact
            path="/facilities"
            component={Facilities}
            accessGranted={determineRouteAccess("/facilities", userAvailableViews)}
          />

          {/* Facility */}
          <PrivateRoute
            auth={auth}
            exact
            path="/facilities/overview"
            component={FacilityOverview}
            accessGranted={determineRouteAccess("/facilities/overview", userAvailableViews)}
          />

          {/* Products */}
          <PrivateRoute
            exact
            path="/products"
            component={Products}
            accessGranted={determineRouteAccess("/products", userAvailableViews)}
          />

          {/* PCA */}
          <PrivateRoute
            exact
            path="/pca/:connectPageName/:productId*"
            component={PCABuilder}
          />

          {/* Devices */}
          <PrivateRoute
            exact
            path="/devices"
            component={Devices}
            accessGranted={determineRouteAccess("/devices", userAvailableViews)}
          />

          {/* Settings */}
          <PrivateRoute
            exact
            path="/settings"
            component={Settings}
            accessGranted={determineRouteAccess("/settings", userAvailableViews)}
          />

          {/* Settings Page */}
          <PrivateRoute
            exact
            path="/settings/:page"
            component={Settings}
            accessGranted={determineRouteAccess("/settings/:page", userAvailableViews)}
          />

          {/* Settings Page Tab */}
          <PrivateRoute
            exact
            path="/settings/:page/:tab"
            component={Settings}
            accessGranted={determineRouteAccess("/settings/:page/:tab", userAvailableViews)}
          />

          {/* Users */}
          <PrivateRoute
            exact
            path="/users"
            component={Users}
            accessGranted={determineRouteAccess("/users", userAvailableViews)}
          />

          {/* User */}
          <PrivateRoute
            exact
            path="/users/:appUserId"
            component={EditUser}
            accessGranted={determineRouteAccess("/users/:appUserId", userAvailableViews)}
          />

          {/* Logout */}
          <Route path="/logout" render={() => logout()} />
        </Switch>
      </Suspense>
    </AuthLayout>
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: props.location },
      }}
    />
  );
};

export default AuthContainer;
