import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { Box, InputLabel, Typography, useTheme } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { isEmpty, isEqual, startCase } from "lodash";
import { isValidDateObject } from "../../../globalStore/utils/helpers";
import { naturalSort } from "../../../utils/naturalSort";
import { submitEditAsset } from "../api";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import CurrencyTextBox from "../../../components/Forms/FieldTypes/CurrencyTextBox";
import Grid from "@mui/material/Grid";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import moment from "moment-timezone";
import Paper from "@mui/material/Paper";
import SimpleSelect from "../../../components/Forms/FieldTypes/Select";
import SimpleTextField from "../../../components/Forms/FieldTypes/TextField";

const currentTime = (timeZone) => {
  return moment()
    .tz(timeZone)
    .format("YYYY-MM-DD");
};

function EditAsset(props) {

  const theme = useTheme();
  const { palette = {} } = theme;
  const { text = {}, typography = {} } = palette;

  const classes = {
    root: {
      flexGrow: 1,
      height: "100%",
    },
    formArea: {
      color: text?.secondary,
      maxHeight: "43vh",
      overflow: "auto",
      padding: theme.spacing(2),
      textAlign: "left",
    },
    label: {
      color: `${typography?.secondary} !important`,
      fontFamily: "Lato !important",
      fontWeight: "bold !important",
      lineHeight: "1 !important",
    },
    submit: {
      marginTop: theme.spacing(4),
    },
    formControl: {
      marginTop: theme.spacing(2),
      width: "90%",
    },
    select: {
      marginTop: theme.spacing(2),
      width: "90%",
    },
    input: {
      width: "90%",
    },
  }

  const {
    apiUrl = "",
    assetData = {},
    classifications = {},
    organization = {},
    setModal = () => { },
    setState = () => { },
    state = {},
    token = '',
  } = props;
  const customs = useSelector((state) => state.organization.customs) || {};

  const { classificationMap = {} } = assetData;
  // remember, the four data types are Alphanumeric, Number, Date, Currency

  const editRef = useRef();
  const classificationsRef = useRef({});

  const requiredFields = useRef();

  const [isValid, setIsValid] = useState(true);
  const { assetCategoriesList = [], categoriesList = [] } = organization;
  const categoryOptionItems = categoriesList.filter(
    // assetMode undefined means the assetMode is Asset
    (item) => item.assetMode === "Asset" || item.assetMode === undefined
  )
    .sort((a, b) => {
      return a.label.localeCompare(b.label);
    })
    .map((element) => {
      return { id: element.id, label: element.id, value: element.id };
    })

  const [editAsset, setEditAsset] = useState(() => {
    let obj = {};
    let assetDataPropertiesMapArray = Object.keys(assetData?.propertiesMap || {}) || []

    // populate all custom asset fields in edit window
    if (customs?.length > 0) {
      customs.forEach((field) => {
        Object.assign(obj, {
          ...obj,
          [field.name]: {
            isCustom: true,
            dataType: field.dataType,
            label: startCase(field.name),
            value: "",
          },
        });
      });
    }

    // Here we verify {assetId}.propertiesMap.description exists. Otherwise we give it a default value.
    // We currently need to set up validation on the backend to verify assets are created the same on the front and backend
    if (!assetDataPropertiesMapArray.includes("description")) {
      assetDataPropertiesMapArray.push('description')
    }

    // Here we verify assetData.category exists. Otherwise we give it a default value.
    if (!assetData?.category) {
      assetDataPropertiesMapArray.push("category")
    }

    // Set timeNeeded to be a date object
    if (assetData?.timeNeededString) {
      Object.assign(obj, {
        ...obj,
        timeNeeded: {
          label: "Need Date",
          dataType: "Date",
          isStandAlone: true,
          value: new Date(assetData.timeNeededString),
        },
      });
    }

    // populate all fields found in asset's propertiesMap, will overwrite custom asset field values above
    if (assetData?.propertiesMap) {
      assetDataPropertiesMapArray
        // We filter out the things we do not want
        .filter(
          (key) =>
            key !== "classificationSet" &&
            key !== "classificationMap" &&
            key !== "deltaTimeHrs" &&
            key !== "distance" &&
            key !== "formData" &&
            key !== "formId" &&
            key !== "kmPerHour" &&
            key !== "note" &&
            key !== "status"
        )
        // We then sort it out
        .sort((a, b) => {
          return a.localeCompare(b);
        })
        .map((k) => {
          return {
            label: k,
            value: assetData.propertiesMap[k],
          };
        })
        .forEach((el) => {
          Object.assign(obj, {
            ...obj,
            [el.label]: {
              label: startCase(el.label),
              value: el.value,
            },
          });
        });
    }

    // Populate values for customs. I put this after the propertiesMap pass, since no final values should
    // be stored in the propertiesMap
    if (assetData?.customMap) {
      Object.keys(assetData?.customMap)
        .sort((a, b) => {
          return a.localeCompare(b);
        })
        .map((item) => {
          return {
            label: item,
            value: assetData.customMap[item],
          };
        })
        .forEach((el) => {
          Object.assign(obj, {
            ...obj,
            [el.label]: {
              isCustom: true,
              label: startCase(el.label),
              value: el.value,
            },
          });
        });
    }

    editRef.current = obj;
    return obj;
  });


  // set required fields depending on selected category, or
  if (assetCategoriesList) {
    const filteredCategory = assetCategoriesList.filter(
      (el) => { return el.id === editAsset.category.value }
    )[0];

    // how required fields are created: 
    // we go through assetCategoriesList (an array of objects. sample obj looks like: {id: 'MO', label: 'MO', requiredFields: Array(6)} ) and if the id matches our category value, we'll be 
    // iterating over its requiredFields to create our official required fields for an edit of current asset.
    // reqioredFields is an array of objects. A requiredfield obj looks like: {id: 'Reference ID', label: 'Reference ID', dataType: 'Alphanumeric', required: false}  

    // finally, we go through requiredfields and officially set our required fields 
    requiredFields.current = filteredCategory
      ? filteredCategory.requiredFields?.reduce((x, y) => {
        return {
          ...x,
          [y.id]: y,
        };
      }, {})
      : {};
  }

  const [selectedClassifications, setSelectedClassifications] = useState(() => {
    // Setting up classifications
    const selectedClassifications = {};

    Object.keys(classificationMap).forEach((item) => {
      const classificationItem = classificationMap[item];
      // We grab the main classification object
      const mainClassificationObject = classifications[item];
      const { classificationId = "", formOptions = [] } =
        mainClassificationObject;

      // We pull the UUID of the form option
      const formOptionId = formOptions.find(
        (element) => element.label === classificationItem
      )?.formOptionId;

      selectedClassifications[classificationId] = {
        label: classificationItem,
        parentName: item,
        value: formOptionId,
      };
    });

    classificationsRef.current = selectedClassifications;

    return selectedClassifications;
  });

  // changesMade and changesMade side effect are used to enable or disable the "submit" button for editing the asset.
  // if no changes were made, i.e., the ref and the current state are equal, then the submit button will not be enabled.
  const [changesMade, setChangesMade] = useState(false);

  useEffect(() => {
    if (
      !isEqual(editAsset, editRef.current) ||
      !isEqual(selectedClassifications, classificationsRef.current)
    ) {
      setChangesMade(true);
    } else {
      setChangesMade(false);
    }
  }, [editAsset, selectedClassifications]);

  return (
    <Box sx={classes.root}>
      <Typography component="h1" style={{ textAlign: "left" }} variant="h5">Edit Asset</Typography>
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12}>
          <Paper sx={classes.formArea} elevation={0}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (isValid) {
                  // Here we are just targeting the description / Category / Asset Details 
                  const diffNotes = Object.keys(editAsset)
                    .map((key) => {
                      if (
                        editAsset[key].value &&
                        !editAsset[key].isCustom &&
                        (key === "category" ||
                          key === "description" ||
                          key === "assetDetails") &&
                        editAsset[key].value !== assetData?.propertiesMap[key]
                      ) {
                        return {
                          key: key,
                          current: assetData.propertiesMap[key]
                            ? assetData.propertiesMap[key]
                            : "",
                          incoming: editAsset[key].value,
                        };
                      }
                      return null;
                    })
                    .filter((el) => el !== null)
                    .map((el) => {
                      return `* ${el?.key ? startCase(el?.key) : "DNP"}: ${el?.current
                        } --> ${el?.incoming}`;
                    })
                    .join("\n\r");

                  // Creating Diff notes for customs
                  const customsDiffNotes = Object.keys(editAsset)
                    .filter((item) => editAsset[item].isCustom)
                    .map((item) => {
                      const newValue = editAsset[item]?.value || "";
                      const initialParentValue =
                        assetData.customMap && assetData.customMap[item]
                          ? assetData.customMap[item]
                          : "";

                      if (newValue !== initialParentValue) {
                        return {
                          key: item,
                          current: initialParentValue,
                          incoming: newValue,
                        };
                      }
                      return null;
                    })
                    .filter((item) => item !== null)
                    .map((element) => {
                      return `* ${element?.key ? startCase(element?.key) : "DNP"
                        }: ${element?.current} --> ${element?.incoming}`;
                    })
                    .join("\n\r");

                  // Creating diff notes for classifications
                  const classificationDiffNotes = Object.keys(
                    selectedClassifications
                  )
                    .map((item) => {
                      const oldRef = { ...classificationsRef };
                      const childClassification = selectedClassifications[item];
                      if (
                        oldRef.current[item] &&
                        oldRef.current[item].value !==
                        selectedClassifications[item].value
                      ) {
                        return {
                          key: childClassification.parentName,
                          current: oldRef.current[item].label,
                          incoming: childClassification.label,
                        };
                      } else if (!oldRef.current[item]) {
                        return {
                          key: "Added New Classification",
                          current: childClassification.parentName,
                          incoming: childClassification.label,
                        };
                      }
                      return null;
                    })
                    .filter((element) => element !== null)
                    .map((element) => {
                      return `* ${element?.key ? startCase(element?.key) : "DNP"
                        }: ${element?.current} --> ${element?.incoming}`;
                    })
                    .join("\n\r");

                  const editTimeStamp = currentTime(state?.filters?.tz);
                  submitEditAsset(
                    { apiUrl, token },
                    assetData,
                    editAsset,
                    selectedClassifications,
                    `Asset Properties Edited (${editTimeStamp}):\n\r ${diffNotes} \n\r ${classificationDiffNotes} \n\r ${customsDiffNotes}`
                  ).then((editResponse) => {
                    if (editResponse.error) {
                      setModal({
                        modalShow: true,
                        text: `Uh-oh! Something went wrong while editing  asset... ${editResponse.error}`,
                        isError: true,
                      });
                    } else {
                      setModal({
                        modalShow: true,
                        text: `Asset update success!`,
                        isError: false,
                      });

                      setState({
                        ...state,
                        assetData: {
                          ...state.assetData,
                          ...editResponse.asset,
                          lastEvent: editResponse.asset.lastEvent,
                          propertiesMap: {
                            ...(state.assetData.propertiesMap || {}),
                            ...editResponse.asset.propertiesMap,
                            note: editResponse.asset.propertiesMap.note,
                          },
                        },
                        histories: {
                          ...state.histories,
                          histories: [editResponse.asset].concat(
                            state.histories.histories
                          ),
                          count: state.histories.count + 1,
                        },
                      });

                      editRef.current = editAsset;
                      classificationsRef.current = selectedClassifications;
                      setChangesMade(false);
                    }
                  });
                } else {
                  setModal({
                    modalShow: true,
                    text: `Please make sure all fields are correctly formatted.`,
                    isError: true,
                  });
                }
              }}
            >
              <Grid container spacing={3}>
                {editAsset && !isEmpty(editAsset) ? (
                  Object.keys(editAsset)
                    .filter((k) => k !== "changesMade")
                    .sort((a, b) => {
                      if (a === "description") {
                        return -3;
                      }
                      if (a === "category") {
                        return -2;
                      }
                      if (a === "timeNeeded") {
                        return -1;
                      }
                      return editAsset[a].label.localeCompare(
                        editAsset[b].label
                      );
                    })
                    .map((key, idx) => {
                      // binLocation was previously stored in propertiesMap.
                      if (key === "binLocation") {
                        return null;
                      } else {
                        return (
                          <Grid item xs={12} sm={4} key={`${idx} - ${key}`}>
                            {(requiredFields?.current &&
                              requiredFields.current[key]?.dataType &&
                              requiredFields.current[key]?.dataType === "Date")
                              ||
                              // if the key is timeNeeded, then we want to show a date picker
                              key === "timeNeeded"
                              ? (
                                <>
                                  <InputLabel sx={classes.label}>{editAsset[key].label}</InputLabel>

                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                      sx={classes.input}
                                      format="MM/dd/yyyy"
                                      id="edit-asset-date"
                                      onChange={(event) => {
                                        setIsValid(isValidDateObject(event));
                                        setEditAsset({
                                          ...editAsset,
                                          [key]: {
                                            ...editAsset[key],
                                            value: event || "",
                                          },
                                        });
                                      }}
                                      slotProps={{
                                        textField: {
                                          //if only the date is not empty and is not a valid date, then show error message
                                          helperText: !isValidDateObject(editAsset[key].value) && editAsset[key].value.length > 0 && "Invalid date",
                                          InputLabelProps: {
                                            style: { color: "transparent" },
                                            shrink: false,
                                          },
                                          label: editAsset[key].label || "",
                                          variant: 'outlined'
                                        }
                                      }
                                      }
                                      value={
                                        editAsset[key].value === ""
                                          ? ""
                                          : editAsset[key].value
                                      }
                                    />
                                  </LocalizationProvider>
                                </>
                              ) : requiredFields?.current &&
                                requiredFields.current[key] &&
                                requiredFields?.current[key]?.dataType &&
                                requiredFields.current[key].dataType ===
                                "Currency" ? (
                                <>
                                  <CurrencyTextBox
                                    onChange={(e) => {
                                      setEditAsset({
                                        ...editAsset,
                                        [key]: {
                                          ...editAsset[key],
                                          value: e.target.value,
                                        },
                                      });
                                    }}
                                    label={editAsset[key].label}
                                    value={editAsset[key].value}
                                    required={
                                      requiredFields.current[key]?.required ||
                                      false
                                    }
                                    variant="standard"
                                  />
                                </>
                              ) : key === "category" ? (
                                <SimpleSelect
                                  classes={classes}
                                  label={editAsset[key].label}
                                  labelStyles={{
                                    marginBottom: '1.3rem'
                                  }}
                                  margin="dense"
                                  onChange={(e) => {
                                    setEditAsset({
                                      ...editAsset,
                                      [key]: {
                                        ...editAsset[key],
                                        value: e.target.value,
                                      },
                                    });
                                  }}
                                  options={categoryOptionItems}
                                  required={requiredFields.current?.[key]?.required || false}
                                  size="large"
                                  value={editAsset[key].value}
                                  variant={"standard"}
                                />
                              ) : (
                                <SimpleTextField
                                  autoComplete={key}
                                  autoFocus={idx === 0}
                                  sx={classes.input}
                                  id={key}
                                  key={`${idx} - ${key}`}
                                  label={editAsset[key].label}
                                  margin="normal"
                                  multiline={
                                    requiredFields.current &&
                                    requiredFields.current[key]?.dataType ===
                                    "number"
                                  }
                                  name={key}
                                  onChange={(e) => {
                                    setEditAsset({
                                      ...editAsset,
                                      [key]: {
                                        ...editAsset[key],
                                        value: e.target.value,
                                      },
                                    });
                                  }}
                                  required={
                                    (requiredFields.current &&
                                      requiredFields.current[key]?.required) ||
                                    false
                                  }
                                  type={
                                    requiredFields.current &&
                                      requiredFields.current[key]?.dataType ===
                                      "number"
                                      ? "number"
                                      : "text"
                                  }
                                  value={editAsset[key].value}
                                  variant="standard"
                                />
                              )}
                          </Grid>
                        );
                      }
                    })
                ) : (
                  <p>Asset has no custom fields to edit!</p>
                )}

                {/* Classifications */}

                {Object.keys(classifications).map(
                  (parentClassification, index) => {
                    const parentObject =
                      classifications[parentClassification] || {};
                    const { formOptions = [] } = parentObject;
                    return (
                      <Grid
                        item
                        key={`${parentClassification}-${index}`}
                        sm={4}
                        xs={12}
                      >
                        <SimpleSelect
                          classes={classes}
                          label={parentClassification}
                          margin="dense"
                          onChange={(event) => {
                            setSelectedClassifications((prevState) => {
                              const newChildLabel = formOptions.find(
                                (element) =>
                                  element.formOptionId === event.target.value
                              )?.label;

                              return {
                                ...prevState,
                                [parentObject.classificationId]: {
                                  label: newChildLabel,
                                  parentName: parentObject.name,
                                  value: event.target.value,
                                },
                              };
                            });
                          }}
                          options={formOptions
                            .map((item) => {
                              const { label, formOptionId } = item;
                              return {
                                name: label,
                                value: formOptionId,
                                label: label,
                              };
                            })
                            .sort((a, b) => naturalSort(a.label, b.label))}
                          value={
                            selectedClassifications[
                              parentObject.classificationId
                            ]
                              ? selectedClassifications[
                                parentObject.classificationId
                              ].value
                              : null
                          }
                          variant="standard"
                        />
                      </Grid>
                    );
                  }
                )}
              </Grid>
              {editAsset && !isEmpty(editAsset) ? (
                <MaterialUiButton
                  className={classes.submit}
                  color="submit"
                  disabled={!changesMade || !isValid}
                  label="Submit"
                  type="submit"
                  variant="contained"
                />
              ) : null}
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default EditAsset;
