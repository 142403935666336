import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  InputLabel,
  TextField,
  Typography,
  useTheme,
} from "@mui/material/";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { isValidDateObject } from "../../../globalStore/utils/helpers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { naturalSort } from "../../../utils/naturalSort";
import { onSubmit } from "./utils";
import { useEffect, useState } from "react";
import AsteriskSolid from "../../../assets/faIcons/AsteriskSolid";
import Autocomplete from "@mui/material/Autocomplete";
import AutocompleteSelect from "../../../components/Forms/FieldTypes/AutocompleteSelect";
import CurrencyTextBox from "../../../components/Forms/FieldTypes/CurrencyTextBox";
import LXDatePicker from "../../../components/Forms/FieldTypes/DatePicker";
import MaterialUiButton from "../../../components/Buttons/MaterialUiButton/MaterialUiButton";
import MuiSVGIcon from "../../../components/IconPicker/MuiSVGIcon";
import NotificationModal from "../../../components/Modals/NotificationModal";
import SimpleTextField from "../../../components/Forms/FieldTypes/TextField";

export default function AssetContent(props) {
  const theme = useTheme();
  const { palette = {} } = theme;
  const { typography = {} } = palette;
  const classes = {
    button: {
      marginLeft: "1rem",
    },
    label: {
      color: typography?.secondary,
      fontFamily: "Lato",
      fontWeight: "bold",
    },
    inputContainer: {
      margin: ".5rem 0",
      padding: "0 .5rem",
    },
    inputField: {
      width: "100%",
    },
  };

  const { customs, facilities, handleAlertModal, organization } = props;

  const [alertModalState, setAlertModalState] = useState({
    alertModalColor: "success",
    modalShow: false,
    modalText: "",
  });

  const [customsExpanded, setCustomsExpanded] = useState(false);
  const [classificationsExpanded, setClassificationsExpanded] = useState(false);

  const handleCustomsExpandClick = () => {
    setCustomsExpanded(!customsExpanded);
  };
  const handleClassificationsExpandClick = () => {
    setClassificationsExpanded(!classificationsExpanded);
  };

  const initialState = {
    assetTag: { error: false, id: "assetTag", isRequired: true, value: "" },
    category: {
      error: false,
      id: "category",
      isRequired: false,
      placeholder: "-Select Category-",
      value: "",
    },
    description: {
      error: false,
      id: "description",
      isRequired: true,
      value: "",
    },
    note: { id: "note", isRequired: false, value: "" },
    quantity: { id: "quantity", isRequired: true, value: "" },
    selectedFacility: {
      error: false,
      id: "selectedFacility",
      isRequired: true,
      placeholder: "-Select Facility-",
      value: "",
    },
    selectedZone: {
      error: false,
      id: "selectedZone",
      isRequired: false,
      placeholder: "-Select Zone-",
      value: "",
    },
    status: {
      error: false,
      id: "status",
      isRequired: true,
      placeholder: "-Select Status-",
      value: "",
    },
    timeCreatedUser: {
      error: false,
      id: "timeCreatedUser",
      isRequired: false,
      value: null,
    },
    timeNeeded: {
      error: false,
      id: "timeNeeded",
      isRequired: false,
      value: null,
    },
    xCoordinate: {
      error: false,
      id: "xCoordinate",
      isRequired: false,
      value: "",
    },
    yCoordinate: {
      error: false,
      id: "yCoordinate",
      isRequired: false,
      value: "",
    },
  };
  const [
    {
      assetTag,
      category,
      description,
      quantity,
      note,
      selectedFacility,
      selectedZone,
      status,
      timeCreatedUser,
      timeNeeded,
      xCoordinate,
      yCoordinate,
    },
    setState,
  ] = useState(initialState);

  const classifications = {};
  for (let currentClassification in props.classifications) {
    const classificationObj = props.classifications[currentClassification];
    if (
      classificationObj.assetMode === "Asset" ||
      classificationObj.assetMode === undefined
    ) {
      classifications[currentClassification] = classificationObj;
    }
  }

  const [availableEvents, setAvailableEvents] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [assetClassifications, setAssetClassifications] = useState({
    ...classifications,
  });
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [customFields, setCustomFields] = useState({});

  useEffect(() => {
    const { eventTypesMap = {}, categoriesList = [] } = organization;

    const assetCustoms = customs.filter(
      (custom) => custom.assetMode === "Asset" || custom.assetMode === undefined
    );

    const availableEventItems = [];
    const categoryOptionItems = [];
    const facilityOptionItems = [];
    const fields = {};

    assetCustoms.forEach((asset) => {
      fields[asset.name] = {
        ...asset,
        required: false,
        error: false,
        value: "",
      };
    });

    // Set Facility Options
    Object.keys(facilities)
      .sort((a, b) => {
        return facilities[a].name?.localeCompare(facilities[b].name);
      })
      .forEach((element) => {
        const { name, facilityId } = facilities[element];
        facilityOptionItems.push({ label: name, value: facilityId });
      });

    // Set Category Options
    [...categoriesList]
      .filter(
        // assetMode undefined means the assetMode is Asset
        (item) => item.assetMode === "Asset" || item.assetMode === undefined
      )
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      })
      .forEach((element) => {
        categoryOptionItems.push({ id: element.id, label: element.id });
      });

    // Set Availble Events
    Object.keys(eventTypesMap)
      .sort((a, b) => {
        return a.localeCompare(b);
      })
      .forEach((element) => {
        if (element === "Tag Destroyed") {
          return null;
        } else {
          availableEventItems.push({ value: element });
        }
      });

    if (Object.keys(fields).length > 0) {
      setCustomFields(fields);
    }

    setAvailableEvents(availableEventItems);
    setCategoryOptions(categoryOptionItems);
    setFacilityOptions(facilityOptionItems);
  }, [customs, facilities, organization, props]);

  function validateFields() {
    let isValidated = true;

    const fieldsToValidate = [
      assetTag,
      category,
      description,
      quantity,
      selectedFacility,
      selectedZone,
      status,
      xCoordinate,
      yCoordinate,
    ];

    fieldsToValidate.forEach((element) => {
      const { value, id } = element;

      if (value.length === 0 && element.isRequired) {
        setState((prevState) => {
          return {
            ...prevState,
            [id]: {
              ...prevState[id],
              error: true,
              errorText: "Required field",
              id,
              value,
            },
          };
        });
        isValidated = false;
      }
    });

    Object.keys(customFields).forEach((element) => {
      const { value, name, required } = customFields[element];
      if (required && value?.length === 0) {
        isValidated = false;
        setCustomFields((prevState) => {
          return {
            ...prevState,
            [name]: {
              ...prevState[name],
              error: true,
              errorText: "Required field",
            },
          };
        });
      }
    });

    if (
      timeCreatedUser.value === null ||
      timeCreatedUser.value.length < 1 ||
      isValidDateObject(timeCreatedUser.value)
    ) {
      setState((prevState) => {
        return {
          ...prevState,
          timeCreatedUser: {
            ...prevState.timeCreatedUser,
            error: false,
            errorText: "",
          },
        };
      });
    } else if (!isValidDateObject(timeCreatedUser.value)) {
      isValidated = false;
      setState((prevState) => {
        return {
          ...prevState,
          timeCreatedUser: {
            ...prevState.timeCreatedUser,
            error: true,
            errorText: "Invalid Date",
          },
        };
      });
    } else {
      console.warn("unaccounted date case");
    }

    //validate timeNeeded
    if (
      timeNeeded.value === null ||
      timeNeeded.value.length < 1 ||
      isValidDateObject(timeNeeded.value)
    ) {
      setState((prevState) => {
        return {
          ...prevState,
          timeNeeded: {
            ...prevState.timeNeeded,
            error: false,
            errorText: "",
          },
        };
      });
    } else if (!isValidDateObject(timeNeeded.value)) {
      isValidated = false;
      setState((prevState) => {
        return {
          ...prevState,
          timeNeeded: {
            ...prevState.timeNeeded,
            error: true,
            errorText: "Invalid Date",
          },
        };
      });
    } else {
      console.warn("unaccounted date case");
    }

    //validate xCoordinate. If there is a value for x, there must be a value for y. And vice versa
    if (xCoordinate.value.length > 0 && yCoordinate.value.length === 0) {
      isValidated = false;
      setState((prevState) => {
        return {
          ...prevState,
          yCoordinate: {
            ...prevState.yCoordinate,
            error: true,
            errorText: "If X coordinate is set, Y coordinate is required",
          },
        };
      });
    } else if (yCoordinate.value.length > 0 && xCoordinate.value.length === 0) {
      isValidated = false;
      setState((prevState) => {
        return {
          ...prevState,
          xCoordinate: {
            ...prevState.xCoordinate,
            error: true,
            errorText: "If Y coordinate is set, X coordinate is required",
          },
        };
      });
    } else {
      setState((prevState) => {
        return {
          ...prevState,
          xCoordinate: {
            ...prevState.xCoordinate,
            error: false,
            errorText: "",
          },
          yCoordinate: {
            ...prevState.yCoordinate,
            error: false,
            errorText: "",
          },
        };
      });
    }

    if (isValidated) {
      let selectedAssetClassifications = {};
      // Handles Classifications
      Object.keys(assetClassifications).forEach((item) => {
        if (
          assetClassifications[item].selectedValue !== null &&
          assetClassifications[item].selectedValue !== undefined &&
          assetClassifications[item].selectedValue.length > 0
        ) {
          // Here we find the parent classification. Then do a .find in the formOptions to pull the childs label
          const selectedItemLabel = assetClassifications[
            item
          ]?.formOptions?.find(
            (element) =>
              element.formOptionId === assetClassifications[item].selectedValue
          )?.label;

          selectedAssetClassifications[item] = selectedItemLabel;
        }
      });

      onSubmit(
        props,
        handleAlertModal,
        {
          assetTag,
          category,
          description,
          note,
          quantity,
          selectedAssetClassifications,
          selectedFacility,
          selectedZone,
          status,
          timeCreatedUser,
          timeNeeded,
          xCoordinate,
          yCoordinate,
        },
        customFields
      ).then((response) => {
        if (response.assetId) {
          setAlertModalState(() => ({
            alertModalColor: "success",
            modalShow: true,
            modalText: "Successfully Created",
          }));
          clearFields();
        } else if (!response.success) {
          setAlertModalState(() => ({
            alertModalColor: "error",
            modalShow: true,
            modalText: response.errors.map((error, index) => {
              return <p key={index}>{error}</p>;
            }),
          }));
        }

        // Delivers the assetId to the parent component and tells it to switch
        // to the next layout
      });
    }
  }

  function clearFields() {
    // Clear Basic Fields
    setState(initialState);

    // Clear Custom Fields
    setCustomFields((prevState) => {
      const newState = prevState;
      Object.keys(newState).forEach(
        (item) =>
        (newState[item] = {
          ...newState[item],
          error: false,
          required: false,
          value: "",
        })
      );

      // did you know if you returned newState instead of {...newState} this whole clear function breaks? Wild
      return { ...newState };
    });

    // Clear Classifications
    setAssetClassifications((prevState) => {
      const newState = prevState;
      Object.keys(newState).forEach(
        (item) =>
        (newState[item] = {
          ...newState[item],
          error: false,
          required: false,
          value: "",
          selectedValue: "",
        })
      );

      // did you know if you returned newState instead of {...newState} this whole clear function breaks? Wild
      return { ...newState };
    });
  }

  return (
    <Grid container>
      <NotificationModal
        confirmationModalShow={alertModalState.modalShow}
        modalClose={() => {
          setAlertModalState(() => ({
            alertModalColor: "success",
            modalShow: false,
            modalText: "",
          }));
        }}
        confirmationText={alertModalState.modalText}
        color={alertModalState.alertModalColor}
      />
      <Grid item xs={12}>
        <Grid display="flex">
          <Typography
            component="p"
            sx={{ color: typography?.secondary, fontWeight: "700" }}
          >
            Required fields have an asterisk
          </Typography>
          <MuiSVGIcon
            icon={<AsteriskSolid />}
            sx={{
              color: "red",
              fontSize: "10px",
            }}
          />
        </Grid>
        <Grid container>
          {/* Asset Tag */}
          <Grid sx={classes.inputContainer} item xs={4}>
            <SimpleTextField
              error={assetTag.error}
              helperText={assetTag.error ? "Required Field" : ""}
              id="outlined-basic"
              label="Asset Tag"
              onChange={(event) => {
                const value = event.target ? event.target.value : "";
                setState((prevState) => ({
                  ...prevState,
                  assetTag: {
                    ...prevState.assetTag,
                    value,
                    error:
                      prevState.assetTag.error && value.length > 0
                        ? false
                        : prevState.assetTag.error,
                  },
                }));
              }}
              required={assetTag.isRequired}
              size="medium"
              value={assetTag.value}
              variant="outlined"
            />
          </Grid>

          {/* Description */}
          <Grid sx={classes.inputContainer} item xs={4}>
            <SimpleTextField
              error={description.error}
              helperText={description.error ? "Required Field" : ""}
              id="description"
              label="Description"
              onChange={(event) => {
                const value = event.target ? event.target.value : "";
                setState((prevState) => ({
                  ...prevState,
                  description: {
                    ...prevState.description,
                    value,
                    error:
                      prevState.description.error && value.length > 0
                        ? false
                        : prevState.description.error,
                  },
                }));
              }}
              required={description.isRequired}
              size="medium"
              value={description.value}
              variant="outlined"
            />
          </Grid>

          {/* Quantity */}
          <Grid sx={classes.inputContainer} item xs={4}>
            <SimpleTextField
              error={quantity.error}
              helperText={quantity.error ? "Required Field" : ""}
              id="quantity"
              label="Quantity"
              onChange={(event) => {
                const value =
                  event.target && !isNaN(parseInt(event.target.value))
                    ? parseInt(event.target.value)
                    : "";
                setState((prevState) => ({
                  ...prevState,
                  quantity: {
                    ...prevState.quantity,
                    value,
                    error: prevState.quantity.error
                      ? false
                      : prevState.quantity.error,
                  },
                }));
              }}
              required={quantity.isRequired}
              size="medium"
              type="number"
              value={quantity.value}
              variant="outlined"
            />
          </Grid>

          {/* Status */}
          <Grid sx={classes.inputContainer} item xs={4}>
            <AutocompleteSelect
              error={status.error}
              errorText={status.errorText}
              getOptionLabel={(option) => option.value || option.placeholder}
              id="status"
              label="Status"
              onChange={(event, value) => {
                if (value) {
                  const { value: itemValue = "" } = value;
                  setState((prevState) => ({
                    ...prevState,
                    status: {
                      ...prevState.status,
                      value: itemValue,
                      error:
                        prevState.status.error && itemValue.length > 0
                          ? false
                          : prevState.status.error,
                    },
                  }));
                }
              }}
              options={availableEvents}
              required={status.isRequired}
              size="large"
              value={status}
            />
          </Grid>

          {/* Facility */}
          <Grid sx={classes.inputContainer} item xs={4}>
            <AutocompleteSelect
              error={selectedFacility.error}
              errorText={selectedFacility.errorText}
              id="facility"
              getOptionLabel={(option) => option.label || option.placeholder}
              label="Facility"
              onChange={(event, value) => {
                if (value) {
                  const { label, value: itemValue = "" } = value;
                  setState((prevState) => ({
                    ...prevState,
                    selectedFacility: {
                      ...prevState.selectedFacility,
                      label,
                      error:
                        prevState.selectedFacility.error && itemValue.length > 0
                          ? false
                          : prevState.selectedFacility.error,
                      value: itemValue,
                    },
                    selectedZone: {
                      ...prevState.selectedZone,
                      label: "-Select-",
                      value: "",
                    },
                  }));
                  //set zone options
                  // each option is an object with a label and value. Label coming from zone.name and value coming from zone.zoneId
                  const zones = facilities[itemValue].zones || [];
                  const zoneOptions = [];
                  zones.forEach((zone) => {
                    zoneOptions.push({ label: zone.name, value: zone.zoneId });
                  });
                  setZoneOptions(zoneOptions);
                }
              }}
              options={facilityOptions}
              required={selectedFacility.isRequired}
              size="large"
              value={selectedFacility}
            />
          </Grid>

          {/* Conditionally render zones autocomplete field if there has been a selected facility.options will come from the selected facility's zone property */}
          {selectedFacility.value.length > 0 ? (
            <Grid sx={classes.inputContainer} item xs={4}>
              <AutocompleteSelect
                id="zone"
                getOptionLabel={(option) => option.label || option.placeholder}
                label="Zone"
                onChange={(event, value) => {
                  if (value) {
                    const { label, value: itemValue = "" } = value;
                    setState((prevState) => ({
                      ...prevState,
                      selectedZone: {
                        ...prevState.selectedZone,
                        label,
                        error:
                          prevState.selectedZone.error && itemValue.length > 0
                            ? false
                            : prevState.selectedZone.error,
                        value: itemValue,
                      },
                    }));
                  }
                }}
                options={zoneOptions}
                size="large"
                value={selectedZone || selectedZone.label}
              />
            </Grid>
          ) : null}

          {/* Category */}
          <Grid sx={classes.inputContainer} item xs={4}>
            <AutocompleteSelect
              error={category.error}
              errorText={category.errorText}
              id="category"
              getOptionLabel={(option) => option.label || option.placeholder}
              label="Category"
              onChange={(event, value) => {
                if (value) {
                  const { id = "" } = value;
                  // Sets the value in state
                  setState((prevState) => ({
                    ...prevState,
                    category: {
                      ...prevState.category,
                      label: id,
                      value: id,
                      error:
                        prevState.category.error && id.length > 0
                          ? false
                          : prevState.category.error,
                    },
                  }));
                  // Checks custom fields to see if any fields need to switched to required
                  // The else statement sets everything to default. In the case they switch
                  // to a category that does not have any custom fields set.
                  const isValidCustomCategory =
                    organization.categoriesList.find((category) => {
                      return category.id === id;
                    });

                  if (isValidCustomCategory) {
                    const { requiredFields = [] } = isValidCustomCategory;
                    setCustomFields((prevState) => {
                      let newState = { ...prevState };
                      requiredFields.forEach((field) => {
                        const { required, label } = field;
                        newState[label] = {
                          ...newState[label],
                          required,
                          error: false,
                        };
                      });
                      return newState;
                    });
                  }
                }
              }}
              options={categoryOptions}
              required={category.isRequired}
              size="large"
              value={category}
            />
          </Grid>

          {/* timeCreatedUser */}
          <Grid sx={classes.inputContainer} item xs={4} key={`timeCreatedUser`}>
            <LXDatePicker
              sx={classes.inputField}
              format="MM/dd/yyyy"
              id={`date-picker-inline-timeCreatedUser`}
              label="Create Date"
              onChange={(event) => {
                setState((prevState) => ({
                  ...prevState,
                  timeCreatedUser: {
                    ...prevState.timeCreatedUser,
                    value: event,
                  },
                }));
              }}
              size="large"
              slotProps={{
                textField: {
                  error: timeCreatedUser.error,
                  helperText: timeCreatedUser.errorText,
                  InputLabelProps: {
                    style: { color: "transparent" },
                    shrink: false,
                  },
                  label: "Create date",
                  variant: "outlined",
                },
              }}
              value={timeCreatedUser.value}
            />
          </Grid>

          {/* timeNeeded */}
          <Grid sx={classes.inputContainer} item xs={4} key={`timeNeeded`}>
            <LXDatePicker
              sx={classes.inputField}
              format="MM/dd/yyyy"
              id={`date-picker-inline-timeNeeded`}
              label="Need Date"
              onChange={(event) => {
                setState((prevState) => ({
                  ...prevState,
                  timeNeeded: {
                    ...prevState.timeNeeded,
                    value: event,
                  },
                }));
              }}
              slotProps={{
                textField: {
                  error: timeNeeded.error,
                  helperText: timeNeeded.errorText,
                  InputLabelProps: {
                    style: { color: "transparent" },
                    shrink: false,
                  },
                  label: "Need date",
                  variant: "outlined",
                },
              }}
              value={timeNeeded.value}
            />
          </Grid>

          {/* x coordinate field */}
          <Grid sx={classes.inputContainer} item xs={4} key={`xCoordinate`}>
            <SimpleTextField
              error={xCoordinate.error}
              errorText={xCoordinate.errorText}
              helperText={xCoordinate.error ? "Required Field" : ""}
              id="xCoordinate"
              label="X Coordinate"
              onChange={(event) => {
                const value = event.target ? event.target.value : "";
                setState((prevState) => ({
                  ...prevState,
                  xCoordinate: {
                    ...prevState.xCoordinate,
                    value,
                    error: prevState.xCoordinate.error
                      ? false
                      : prevState.xCoordinate.error,
                  },
                }));
              }}
              size="medium"
              type="number"
              value={xCoordinate.value}
              variant="outlined"
            />
          </Grid>

          {/* y coordinate field */}
          <Grid sx={classes.inputContainer} item xs={4} key={`yCoordinate`}>
            <SimpleTextField
              error={yCoordinate.error}
              errorText={yCoordinate.errorText}
              id="yCoordinate"
              label="Y Coordinate"
              onChange={(event) => {
                const value = event.target ? event.target.value : "";
                setState((prevState) => ({
                  ...prevState,
                  yCoordinate: {
                    ...prevState.yCoordinate,
                    value,
                    error: prevState.yCoordinate.error
                      ? false
                      : prevState.yCoordinate.error,
                  },
                }));
              }}
              size="medium"
              type="number"
              value={yCoordinate.value}
              variant="outlined"
            />
          </Grid>

          {/* Asset Details */}
          <Grid sx={classes.inputContainer} item xs={12}>
            <SimpleTextField
              multiline
              id="asset-details"
              label="Asset Details"
              onChange={(event) => {
                const value = event.target ? event.target.value : "";
                setState((prevState) => ({
                  ...prevState,
                  note: { ...prevState.note, label: value, value: value },
                }));
              }}
              size="large"
              variant="outlined"
              value={note.label}
            />
          </Grid>

          {/* Custom Fields */}
          <Grid xs={12} marginTop="10px">
            <Accordion
              expanded={customsExpanded}
              onChange={handleCustomsExpandClick}
            >
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls="customs-panel-content"
                id="customs-panel-header"
              >
                <Typography
                  sx={{ color: typography?.secondary, fontWeight: "700" }}
                >
                  Custom Fields
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container xs={12}>
                  {/* {renderedCustomFields} */}
                  {Object.keys(customFields).map((element, index) => {
                    if (customFields[element].customId) {
                      let {
                        dataType,
                        error = false,
                        errorText = "",
                        customId,
                        name = "",
                        required = false,
                        value = "",
                      } = customFields[element];

                      switch (dataType) {
                        case "Currency":
                          return (
                            <Grid
                              sx={classes.inputContainer}
                              item
                              key={`${customId}-${index}`}
                              xs={4}
                            >
                              <CurrencyTextBox
                                fullWidth={true}
                                error={error}
                                label={name}
                                onChange={(event) => {
                                  setCustomFields((prevState) => ({
                                    ...prevState,
                                    [name]: {
                                      ...prevState[name],
                                      error: false,
                                      value: event.target.value,
                                    },
                                  }));
                                }}
                                required={required}
                                size="large"
                                value={value}
                                variant="outlined"
                              />
                            </Grid>
                          );
                        case "Date":
                          return (
                            <Grid
                              sx={classes.inputContainer}
                              item
                              xs={4}
                              key={`${customId}-${index}`}
                            >
                              <InputLabel sx={classes.label}>{name}</InputLabel>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  sx={classes.inputField}
                                  format="MM/dd/yyyy"
                                  id={`date-picker-inline-${customId}`}
                                  onChange={(event) => {
                                    setCustomFields((prevState) => ({
                                      ...prevState,
                                      [name]: {
                                        ...prevState[name],
                                        error: false,
                                        value: event,
                                      },
                                    }));
                                  }}
                                  required={required}
                                  slotProps={{
                                    textField: {
                                      error: error,
                                      helperText: errorText,
                                      InputLabelProps: {
                                        style: { color: "transparent" },
                                        shrink: false,
                                      },
                                      label: name,
                                      variant: "outlined",
                                    },
                                  }}
                                  value={value?.length > 0 ? value : null}
                                />
                              </LocalizationProvider>
                            </Grid>
                          );
                        default:
                          return (
                            <Grid
                              sx={classes.inputContainer}
                              item
                              key={`${customId}-${index}`}
                              xs={4}
                            >
                              <InputLabel sx={classes.label}>{name}</InputLabel>
                              <TextField
                                sx={classes.inputField}
                                error={error}
                                helperText={error ? "Required Field" : ""}
                                label={null}
                                onChange={(event) => {
                                  // if datatype is numeric, parse the event.target.value to an integer
                                  let value;
                                  if (dataType === "Numeric") {
                                    value = event.target
                                      ? parseInt(event.target.value)
                                      : "";
                                  } else {
                                    value = event.target
                                      ? event.target.value
                                      : "";
                                  }
                                  setCustomFields((prevState) => ({
                                    ...prevState,
                                    [name]: {
                                      ...prevState[name],
                                      value,
                                      error: false,
                                    },
                                  }));
                                }}
                                required={required}
                                type={`${dataType === "Numeric" ? "number" : ""
                                  }`}
                                value={value}
                                variant="outlined"
                              />
                            </Grid>
                          );
                      }
                    }
                    return null;
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          {/* Classifications */}
          <Grid xs={12} marginTop="10px">
            <Accordion
              expanded={classificationsExpanded}
              onChange={handleClassificationsExpandClick}
            >
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls="classifications-panel-content"
                id="classifications-panel-header"
              >
                <Typography
                  sx={{ color: typography?.secondary, fontWeight: "700" }}
                >
                  Classifications
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container xs={12}>
                  {Object.keys(assetClassifications).map((parent, index) => {
                    const { formOptions, name } = assetClassifications[parent];
                    const childrenOptions = [];

                    // Populates the select options
                    Object.keys(formOptions).forEach((child) => {
                      childrenOptions.push({
                        label: formOptions[child].label,
                        parentId: formOptions[child].referenceId,
                        value: formOptions[child].formOptionId,
                      });
                    });

                    return (
                      <Grid
                        sx={classes.inputContainer}
                        item
                        key={`${name}-${index}`}
                        xs={4}
                      >
                        <InputLabel sx={classes.label}>{name}</InputLabel>
                        <Autocomplete
                          id={name}
                          options={childrenOptions.sort((a, b) =>
                            naturalSort(a.label, b.label)
                          )}
                          onChange={(event, value) => {
                            if (value) {
                              const { value: itemValue = "" } = value;
                              setAssetClassifications((prevState) => ({
                                ...prevState,
                                [name]: {
                                  ...prevState[name],
                                  selectedValue: itemValue,
                                  value,
                                },
                              }));
                            } else {
                              setAssetClassifications((prevState) => ({
                                ...prevState,
                                [name]: {
                                  ...prevState[name],
                                  selectedValue: value,
                                  value,
                                },
                              }));
                            }
                          }}
                          getOptionLabel={(option) => option.label}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={null}
                              variant="outlined"
                            />
                          )}
                          value={
                            assetClassifications[name].value || {
                              label: "",
                              value: "",
                            }
                          }
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box sx={classes.inputContainer} textAlign="right" width="100%">
          {/* Clear Button */}
          <MaterialUiButton
            className={classes.button}
            color="cancel"
            label="Clear"
            onClick={() => {
              clearFields();
            }}
            variant="outlined"
          />

          {/* Submit Button */}
          <MaterialUiButton
            className={classes.button}
            color="submit"
            label="submit"
            onClick={() => {
              validateFields();
            }}
            variant="contained"
          />
        </Box>
      </Grid>
    </Grid>
  );
}
