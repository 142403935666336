import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { cloneDeep, isEqual } from "lodash";
import { Box, Grid, Typography } from "@mui/material";
import {
  thunks,
  updateUsersMap,
} from "../../globalStore/slices/organization/organizationSlice";
import { getChildrenOrganizationUsers } from "../../utils/API/Organization/Organization";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ActionButtons from "../../components/Buttons/ActionButtons";
import AddIcon from '@mui/icons-material/Add';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import ConfirmationModalContent from "../../components/Modals/ConfirmationModalContent";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EditUser from "./EditUser";
import HoverIconButton from "../../components/ReusedComponents/HoverIconButton";
import Loading from "../../components/Loading/Loading";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MaterialConfirmationModal from "../../components/Modals/MaterialConfirmationModal";
import MaterialUiButton from "../../components/Buttons/MaterialUiButton/MaterialUiButton";
import MaterialUiDataGridPremium from "../../components/Tables/DataGridPremium/MaterialUiDataGridPremium";
import ModalDialog from "../../components/Modals/ModalDialog/ModalDialog";

const ImportUsers = lazy(() => import("./ImportUsers"));

export default function Users(props) {
  const {
    apiUrl,
    handleGoBack = () => {},

    // The users component can be used to display the main orgs users or users of one of its child orgs.
    isMainOrg = true,
    selectedOrganization = {},
    token,
    userAvailableActions,
    userId
  } = props;
  const [childUsersMap, setChildUsersMap] = useState({});
  const usersMap = useSelector(
    (state) =>
      Object.keys(state.organization.usersMap).map(
        (k) => state.organization.usersMap[k]
      ),
    isEqual
  );

  const sortedUsersMap = usersMap.sort((a, b) => {
    if (a.lastName < b.lastName) {
      return -1;
    }
    if (a.lastName > b.lastName) {
      return 1;
    }
    return 0;
  });

  // The initial users comes in as a map, we then turn it into an array and then sort.
  const sortedChildUsersMap = Object.keys(childUsersMap)
    .map((k) => childUsersMap[k])
    .sort((a, b) => {
      if (a.lastName < b.lastName) {
        return -1;
      }
      if (a.lastName > b.lastName) {
        return 1;
      }
      return 0;
    });

  const { childOrganizations, organization, consoleRoles, mobileRoles } =
    useSelector((state) => state.organization, isEqual);

  const [state, setState] = useState({
    checked: {},
    confirmationModalShow: false,
    confirmationText: "",
    groups:
      Object.keys(childOrganizations).map((k) => childOrganizations[k]) || [],
    isCreate: false,
    isProcessing: false,
    modal: "",
    modalShow: false,
    modalTitle: "",
    organization: organization,
    selectedUser: {},
  });
  const {
    confirmationModalShow,
    confirmationText,
    groups = [],
    isCreate,
    modal,
    modalTitle,
    notificationModalColor,
    readOnly,
    selectedUser = {},
  } = state;
  const { propertiesMap = {} } = organization;

  const handleState = (updatedState) => {
    setState((s) => {
      return {
        ...s,
        ...updatedState,
      };
    });
  };

  const dispatchGlobal = useDispatch();
  const location = useLocation();

  const { retrieveAppUsers: retrieveAppUsersThunk } = thunks.appUsers;

  // onSuccess function retrieves child organizations, patch/diff with redux store, and resets location state
  const resetLocationState = () => {
    location.state = {};
  };

  // wrapping init up in useCallback to prevent useEffect loop
  const init = useCallback(
    (isMounted) => {
      if (!isMounted) {
        dispatchGlobal(retrieveAppUsersThunk());
      }
    },
    [dispatchGlobal, retrieveAppUsersThunk]
  );

  const mounted = useRef(false);

  useEffect(() => {
    const { state: routerState = {} } = props.location || {};
    const {
      modal = "",
      modalShow = false,
      modalTitle = "",
      isCreate = false,
      userId = "",
    } = routerState;

    const selectedUser = usersMap.find((user) => user.appUserId === userId) || {};

    setState((s) => {
      return {
        ...s,
        isCreate,
        selectedUser,
      };
    });

    if (modalShow) {
      setState((s) => {
        return {
          ...s,
          modal,
          modalTitle,
          modalShow,
        };
      });
    }

    init(mounted.current);

    mounted.current = true;

    // This cleans up the quickLinks and modal behavior
    return props.history.listen((location) => {
      setState((s) => {
        return { ...s, ...location.state };
      });
    });
  }, [props, init]);

  // This useEffect fires off if this users page is meant to retrieve child organization users
  useEffect(() => {
    if (!isMainOrg) {
      getChildrenOrganizationUsers({
        ...props,
        organizationId: selectedOrganization?.organizationId,
      }).then((res) => {
        if (res?.success) {
          let newUsersMap = {};

          // turn the array into a map
          res.appUsers.forEach((user) => {
            newUsersMap[user.appUserId] = user;
          });

          setChildUsersMap(newUsersMap);
        }
      });
    }
  }, [isMainOrg, props, selectedOrganization?.organizationId]);

  const handleUserDelete = (appUserId) => {
    fetch(`${props.apiUrl}appUsers/${appUserId}`, {
      method: "DELETE",
      headers: {
        "auth-token": token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          // If the user is on the main application, we need to delete the user from the redux store
          // Otherwise remove it from the childUsersMap
          if (isMainOrg) {
            dispatchGlobal(
              updateUsersMap({
                appUserId,
                isDelete: true,
              })
            );
          } else {
            setChildUsersMap((prevState) => {
              let newState = { ...prevState };
              delete newState[appUserId];
              return newState;
            });
          }
          resetLocationState();
          handleConfirmationModal("User Deleted");
          handleState({ modalShow: false, selectedUser: {} });
        }
      });
  };

  const handleUnlockAccount = async (appUserId) => {
    return await fetch(`${props.apiUrl}appUsers/${appUserId}/unlock`, {
      headers: {
        "Content-Type": "application/json",
        "auth-token": props.token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          dispatchGlobal(
            updateUsersMap({
              appUserId: res.appUser?.appUserId,
              body: res.appUser,
            })
          );
          resetLocationState();
          handleConfirmationModal("Account Unlocked");
          handleState({ modalShow: false, selectedUser: {} });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleConfirmationModal = (confirmationText, color) => {
    handleState({
      confirmationModalShow: true,
      confirmationText,
      notificationModalColor: color,
    });
  };

  const modalClose = () =>
    handleState({
      confirmationModalShow: false,
      modalShow: false,
      selectedUser: {},
      readOnly: false,
    });

  const userColumns = [
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
      renderCell: (data) => {
        const { row } = data;
        if (data.rowNode.type === "group") {
          return data.value;
        }
        return (
          <div>
            {row.firstName}
          </div>
        );
      },
      valueGetter: (value, row) => row?.firstName || "",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
      renderCell: (data) => {
        const { row } = data;
        if (data.rowNode.type === "group") {
          return data.value;
        }
        return (
          <div>
            {row.lastName}
          </div>
        );
      },
      valueGetter: (value, row) => row?.lastName || "",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: (data) => {
        const { row } = data;
        if (data.rowNode.type === "group") {
          return data.value;
        }
        return (
          <div>
            {row.email}
          </div>
        );
      },
      valueGetter: (value, row) => row?.email || "",
    },
    {
      disableColumnMenu: true,
      disableSortBy: true,
      field: "actions",
      flex: 1,
      groupable: false,
      headerName: "Actions",
      sortable: false,
      renderCell: (data) => {
        const { row } = data;
        if (data.rowNode.type === "group") return null;
        const buttonIcons = [];

        if (userAvailableActions.includes("Edit Users")) {
          buttonIcons.push({
            action: () => {
              handleState({
                isCreate: false,
                modal: "Edit or Create User",
                modalShow: true,
                modalTitle: "Edit User",
                selectedUser: row,
              });
            },
            icon: <EditIcon/>,
            title: "Edit",
          });
        }

        if (userAvailableActions.includes("Delete Users")) {
          buttonIcons.push({
            icon: <DeleteIcon/>,
            title: "Delete",
            action: () => {
              handleState({
                modal: "Delete User",
                modalShow: true,
                modalTitle: "Delete User",
                selectedUser: row,
              });
            },
          });
        }

        if (userAvailableActions.includes("Unlock Users")) {
          buttonIcons.push({
            icon: row.locked ? <LockIcon/> : <LockOpenIcon/>,
            disabled: !row.locked,
            title: "Unlock Account",
            action: () => {
              handleState({
                modal: "Unlock Account",
                modalShow: true,
                modalTitle: "Unlock Account",
                selectedUser: row,
              });
            },
          });
        }

        return <ActionButtons content={buttonIcons} />;
      },
    },
  ];

  // This controls which modal the <CenterModal /> displays
  const switchModal = (modal) => {
    switch (modal) {
      case "Edit or Create User":
        return (
          <EditUser
            apiUrl={apiUrl}
            consoleRoles={consoleRoles}
            dispatchGlobal={dispatchGlobal}
            groups={groups}
            hasFormFields={true}
            isCreate={isCreate}
            isMainOrg={isMainOrg}
            mobileRoles={mobileRoles}
            notificationModal={handleConfirmationModal}
            onHide={modalClose}
            onSuccess={resetLocationState}
            organization={organization}
            readOnly={readOnly}
            roles={propertiesMap.roles}
            selectedUser={cloneDeep(selectedUser)}
            selectedOrganization={selectedOrganization}
            setChildUsersMap={setChildUsersMap}
            token={token}
            updateUsersMap={updateUsersMap}
            userId={userId}
          />
        );
      case "Delete User":
        return (
          <ConfirmationModalContent
            content={`Are you sure you want to delete ${selectedUser.firstName} ${selectedUser.lastName}? This action cannot be
              undone.`}
            handleSubmit={() => {
              handleUserDelete(selectedUser.appUserId);
            }}
            handleCancel={() => {
              modalClose();
            }}
          />
        );
      case "Unlock Account":
        return (
          <ConfirmationModalContent
            content={
              selectedUser.locked
                ? `This account is currently locked out of LXConnect. Would you like to unlock?`
                : `This account is not currently locked out of LXConnect.`
            }
            handleSubmit={() => {
              handleUnlockAccount(selectedUser.appUserId);
            }}
            handleCancel={() => {
              modalClose();
            }}
          />
        );
      case "Import Users":
        return (
          <Suspense fallback={<Loading />}>
            <ImportUsers
              apiUrl={apiUrl}
              consoleRoles={consoleRoles}
              groups={groups}
              mobileRoles={mobileRoles}
              notificationModal={handleConfirmationModal}
              onSuccess={resetLocationState}
              organization={organization}
              roles={propertiesMap.roles}
              token={token}
            />
          </Suspense>
        );
      default:
        return;
    }
  };

  function toolBarButtons() {
    return (
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "2rem",
        }}
      >
        {userAvailableActions.includes("Create Users") ? (
          <>
            <HoverIconButton
              icon={<AddIcon/>}
              iconDirection="right"
              cypressId="users-btn-add-user"
              text="Add User"
              handleClick={() =>
                handleState({
                  isCreate: true,
                  modal: "Edit or Create User",
                  modalShow: true,
                  modalTitle: "Add User",
                })
              }
            />

            {/* Currently we are able to import users to the main organization. But I am unsure if we can do this to a child organization */}
            {isMainOrg ? (
              <HoverIconButton
                icon={<AssignmentReturnedIcon/>}
                iconDirection="right"
                cypressId="users-btn-import-users"
                text="Import Users"
                handleClick={() =>
                  handleState({
                    modal: "Import Users",
                    modalShow: true,
                    modalTitle: "Import Users",
                  })
                }
              />
            ) : null}
          </>
        ) : null}
      </Grid>
    );
  }

  return (
    <Box>
      <Grid
        container
        sx={{
          margin: "0 auto",
          width: "84vw",
        }}
      >
        <ModalDialog
          content={switchModal(modal)}
          handleClose={modalClose}
          open={state.modalShow}
          title={modalTitle}
        />
        <MaterialConfirmationModal
          content={confirmationText}
          closeModal={modalClose}
          modalOpen={confirmationModalShow}
          severity={notificationModalColor ? "error" : "success"}
          variant="filled"
        />

        {/* Header */}
        <Grid item xs={12}>
          <Box mt={4} mb={3} textAlign="left">
            <Typography component="h1" variant="h4">
              {isMainOrg ? "" : `${selectedOrganization.name} `}Users
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Go Back Button */}
      {isMainOrg ? null : (
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "end", marginBottom: "1rem" }}
        >
          <MaterialUiButton label="Go Back" onClick={handleGoBack} />
        </Grid>
      )}

      {/* Table */}
      <Grid item xs={12}>
        <MaterialUiDataGridPremium
          autoHeight={false}
          checkboxSelection={true}
          columns={userColumns}
          enableIsRowSelectable={false}
          exportFileName="Users"
          getRowId={(row) => row.appUserId}
          loading={false}
          needsCustomToolbar={true}
          rowCount={usersMap.length}
          rows={isMainOrg ? sortedUsersMap : sortedChildUsersMap}
          toolBarButtons={toolBarButtons}
        />
      </Grid>
    </Box>
  );
}
