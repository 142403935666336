import moment from "moment-timezone";
import { Grid } from "@mui/material";
import { useTranslation as UseTranslation } from "react-i18next";
import {
  utc_to_local_24_hour_clock,
} from "../../utils/date_time_utils";
import MaterialUiDataGridPro from "../../components/Tables/DataGridPro/MaterialUiDataGridPro";
import PCAWarningIcon from "../../components/ReusedComponents/PCAWarningIcon";
import MaterialUiDataGridPremium from "../../components/Tables/DataGridPremium/MaterialUiDataGridPremium";

export default function Table(props) {
  const {
    facilities,
    state,
    timeZone,
    zones,
  } = props;

  const { t } = UseTranslation();

  const tableColumns = [
    {
      field: "timeOfLog",
      flex: 1,
      headerName: "Date/Time",
      id: "timeOfLog",
      valueGetter: (data) =>
        utc_to_local_24_hour_clock(data?.row?.timeOfLog, timeZone) ||
        t("dnpinput1"),
    },
    {
      field: "assetType",
      flex: 1,
      headerName: "Unit of Measure",
      id: "assetType",
      valueGetter: (data) => data?.row?.assetType || t("dnpinput1"),
    },
    {
      field: "assetTag",
      flex: 1,
      headerName: "Item Tag",
      id: "assetTag",
      valueGetter: (data) => data?.row?.assetTag || t("dnpinput1"),
    },
    {
      field: "event",
      flex: 1,
      headerName: "Event",
      id: "event",
      valueGetter: (data) => data?.row?.event || t("dnpinput1"),
    },
    {
      field: "user",
      flex: 1,
      headerName: "User",
      id: "user",
      valueGetter: (data) => parseUserInfo(data?.row),
    },
    {
      field: "facility",
      flex: 1,
      headerName: "Facility Name",
      id: "facility",
      valueGetter: (data) => {
        const { facilityId, latitude, longitude } = data?.row || {};
        return !latitude || !longitude
          ? t("dnpinput1")
          : facilityId && facilities[facilityId] && facilities[facilityId].name
            ? facilities[facilityId].name
            : "Not Registered";
      },
    },
    {
      field: "zone",
      flex: 1,
      headerName: "Zone",
      id: "zone",
      valueGetter: (data) => {
        const { zone = {} } = data?.row || {};
        const { zoneId = "" } = zone;
        return zoneId && zones[zoneId] ? zones[zoneId].name : t("dnpinput1");
      },
    },
    {
      field: "binLocation",
      flex: 1,
      headerName: "Bin Location",
      id: "binLocation",
      valueGetter: (data) => data?.row?.binLocation || "",
    },
    {
      field: "cityState",
      flex: 1,
      headerName: "City, State",
      id: "cityState",
      valueGetter: (data) => {
        const { city = t("dnpinput1"), state = t("dnpinput1") } = data?.row || {};
        return `${city}${state !== undefined ? `, ${state}` : ""}`;
      },
    },
    {
      field: "latLong",
      flex: 1,
      headerName: "Latitude, Longitude",
      id: "latLong",
      valueGetter: (data) => {
        const { latitude, longitude } = data?.row || {};
        return `${latitude || t("dnpinput1")}, ${longitude || t("dnpinput1")}`;
      },
    },
    {
      field: "flagged",
      flex: 1,
      headerName: "PCA",
      id: "flagged",
      renderrenderCell: (data) => {
        const { flagged } = data?.row || {};

        return flagged ? <PCAWarningIcon data={data} /> : null;
      },
    },
  ];

  const parseUserInfo = (row, firstLast = false) => {
    if (firstLast && row.appUserId && (row.firstName || row.lastName)) {
      return `${row.firstName || ""}${row.firstName && row.lastName ? " " : ""
        }${row.lastName || ""}`;
    }
    if (row.appUserId && (row.firstName || row.lastName)) {
      return `${row.lastName || ""}${row.firstName && row.lastName ? ", " : ""
        }${row.firstName || ""}`;
    } else {
      return ``;
    }
  };

  return (
    <Grid item xs={12}>
      <MaterialUiDataGridPro
        checkboxSelection={false}
        columns={tableColumns}
        disableExportOption={false}
        getRowId={(row) => row.assetId || row.assetHistoryId || row.id}
        loading={false}
        needsCustomToolbar={true}
        rowCount={state.histories?.assetHistories.length}
        rows={state.histories?.assetHistories}
      />
    </Grid>
  );
}

export function ProductInformationTable(props) {
  const { state } = props;
  const productTableColumns = [
    // Product Name
    {
      field: "name",
      flex: 1,
      headerName: "Product Name",
      id: "name",
      sortable: false,
      renderCell: (data) => {
        const { name = "" } = data?.row || {}

        return <div>{name ? name : "N/A"}</div>;
      }
    },
    // Product Code
    {
      field: "tag",
      headerName: "Product Code",
      id: "tag",
      renderCell: (data) => {
        const { tag = "" } = data?.row || {}

        return <div>{tag ? tag : "N/A"}</div>;
      },
      sortable: false,
    },
    // Product Type
    {
      field: "productType",
      headerName: "Product Type",
      id: "productType",
      sortable: false,
      renderCell: (data) => {
        const { productType = "", propertiesMap = {} } = data?.row || {}
        const type = productType
          ? productType
          : propertiesMap?.productType
            ? propertiesMap.productType
            : "";
        return <div>{type ? type : "N/A"}</div>;
      },
    },
    // Product Description
    {
      field: "description",
      headerName: "Product Description",
      id: "description",
      renderCell: (data) => {
        const { description = "N/A" } = data?.row || {};
        return <div>{description}</div>;
      },
      sortable: false,
    },
  ]

  return (
    <MaterialUiDataGridPremium
      columns={productTableColumns}
      getRowId={(row) => row.productId}
      rows={state.productData ? [...state.productData] : []}
    />
  );
}

export function BatchInformationTable(props) {
  const { state, facilityMap, timeZone } = props;
  const batchTableColumns = [
    {
      field: "name",
      flex: 1,
      headerName: "Batch ID",
      id: "name",
      renderCell: (data) => {
        const row = data;
        const { name = "", tag = "" } = row;
        return <div>{tag ? tag : name}</div>;
      },
    },
    {
      field: "timeCreated",
      headerName: "Date Created",
      id: "timeCreated",
      renderCell: (data) => {
        const row = data;
        const { timeCreated = "" } = row;
        return (
          <div>
            {moment
              .utc(timeCreated, "YYYYMMDDHHmmss")
              .tz(timeZone || moment.tz.guess())
              .format("MM/DD/YYYY") || t("dnpinput1")}
          </div>
        );
      },
    },
    {
      field: "originLocation",
      headerName: "Batch Origin",
      id: "originLocation",
      renderCell: (data) => {
        const row = data;
        const { originLocation = {} } = row;
        const { facilityId = "" } = originLocation;
        const label = facilityMap[facilityId]
          ? facilityMap[facilityId].name
          : "N/A";

        return <div>{label}</div>;
      },
    },
  ]
  const { t } = UseTranslation();

  return (
    <MaterialUiDataGridPremium
      columns={batchTableColumns}
      getRowId={(row) => row.batchId}
      rows={state.batchData ? [state.batchData] : []}
    />
  );
}
