import { Grid, useTheme } from "@mui/material";
import AsteriskSolid from "../../../assets/faIcons/AsteriskSolid";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiSVGIcon from "../../IconPicker/MuiSVGIcon";
import Typography from "@mui/material/Typography";

function ModalDialog(props) {
  const {
    content,
    dialogActions,
    fullScreen = false,
    handleClose,
    hasFormFields,
    isTabs,
    maxWidth,
    open,
    title,
  } = props;

  const theme = useTheme();
  const { palette = {} } = theme;
  const { third = {}, typography = {} } = palette;

  const contentClasses = {
    root: {
      padding: "8px 16px",
    },
  };

  const titleClasses = {
    closeButton: {
      color: theme.palette.grey[500],
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    root: {
      margin: 0,
      minWidth: "600px",
      padding: theme.spacing(2),
    },
    title: {
      color: third?.main,
      fontFamily: "Lato",
      fontWeight: "bold",
    },
  };

  const classes = {
    paper: {
      maxHeight: "90%",
      maxWidth: "800px",
      overflowX: "hidden",
    },
  };

  const formFieldsExist =
    hasFormFields || (content && content.props && content.props.hasFormFields);

  return (
    <Dialog
      aria-labelledby={title}
      classes={{ paper: classes.paper }}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      onClose={(e) => {
        e.preventDefault();
        handleClose();
      }}
      open={open}
    >
      {open ? (
        <>
          {!isTabs ? (
            <MuiDialogTitle
              sx={{ ...titleClasses.root, ...titleClasses.title }}
            // {...other}
            >
              <Typography sx={titleClasses.title} variant="h6">
                {/* {children} */}
                {title}
              </Typography>
              {handleClose ? (
                <IconButton
                  aria-label="close"
                  sx={titleClasses.closeButton}
                  onClick={handleClose}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              ) : null}
            </MuiDialogTitle>
          ) : null}

          <MuiDialogContent sx={contentClasses.root} dividers>
            <>
              {formFieldsExist ? (
                <Grid display="flex">
                  <Typography
                    component="p"
                    sx={{ color: typography?.secondary, fontWeight: "700" }}
                  >
                    Required fields have an asterisk
                  </Typography>
                  <MuiSVGIcon
                    icon={<AsteriskSolid />}
                    sx={{
                      color: "red",
                      fontSize: "10px",
                    }}
                  />
                </Grid>
              ) : null}

              {content}
            </>
          </MuiDialogContent>
          {dialogActions ? (
            <DialogActions>{dialogActions}</DialogActions>
          ) : null}
        </>
      ) : null}
    </Dialog>
  );
}

export default ModalDialog;
